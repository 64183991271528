<!-- Latest CDN production CSS -->
<link
  href="https://global.oktacdn.com/okta-signin-widget/5.3.3/css/okta-sign-in.min.css"
  type="text/css"
  rel="stylesheet"
/>
<app-notify
  aria-live="polite"
  aria-atomic="true"
></app-notify>
<app-notify-with-action />

<div
  class="browserNotSupported"
  *ngIf="browserNotSupported"
>
  <h1>Your Browser is NOT Supported</h1>
</div>
<ng-container *ngIf="!browserNotSupported">
  <router-outlet
    *ngIf="(authService.loadingSession$ | async) === false; else loading"
  ></router-outlet>
  <p-confirmDialog
    styleClass="dialog-sm"
    [key]="'confirmUnsavedChangesVendor'"
  ></p-confirmDialog>
</ng-container>

<ng-template #loading>
  <div class="load">
    <img
      title="loading"
      class="loading-logo"
      src="/assets/sustainment-icons/sustain-icon-white.png"
    />
  </div>
</ng-template>

<p-confirmDialog
  [key]="'timoutDialog'"
  [closable]="false"
  [baseZIndex]="10000"
  styleClass="dialog-sm"
></p-confirmDialog>
<p-confirmDialog
  class="unsaved-changes"
  styleClass="dialog-sm"
  [key]="'unsavedChanges'"
/>
