import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IRoleState } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'role' })
export class RoleStore extends Store<IRoleState> {
  public constructor() {
    super({});
  }
}
