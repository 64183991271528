import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IRecentlyViewedCompanies } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'recently-viewed' })
export class RecentlyViewedStore extends Store<IRecentlyViewedCompanies> {
  public constructor() {
    super({});
  }
}
