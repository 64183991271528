import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-login-container',
    templateUrl: './login.container.html',
    standalone: false
})
export class LoginContainerComponent {
  public constructor(
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    if (this.route.snapshot.url[1]?.path === 'register') {
      this.titleService.setTitle('Sign up - Sustainment Portal');
    } else {
      this.titleService.setTitle('Login - Sustainment Portal');
    }
  }
}
