import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { INoteList } from 'sustainment-component';
import { NoteStore } from './note.store';

@Injectable({ providedIn: 'root' })
export class NoteQuery extends Query<INoteList> {
  public constructor(protected store: NoteStore) {
    super(store);
  }
}
