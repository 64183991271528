import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IBookmarkList } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bookmarks' })
export class BookmarkStore extends Store<IBookmarkList> {
  public constructor() {
    super({});
  }
}
