import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SearchConfiguration } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'searchControl' })
export class SearchConfigurationStore extends Store<SearchConfiguration> {
  public constructor() {
    super({});
  }
}
