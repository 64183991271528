import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { INoteList } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notes' })
export class NoteStore extends Store<INoteList> {
  public constructor() {
    super({});
  }
}
