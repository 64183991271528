import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OktaTokenInterceptor implements HttpInterceptor {
  private readonly _msg = 'Token failed validation';

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error &&
          error.status === 500 &&
          (error.error?.message?.includes(this._msg) ||
            error.message?.includes(this._msg))
        ) {
          window.location.reload();
          return of();
        }

        return throwError(() => error);
      })
    );
  }
}
