import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ILocation, IMapSettingState } from 'sustainment-component';
import { MapSettingStore } from './map-setting.store';

@Injectable({ providedIn: 'root' })
export class MapSettingQuery extends Query<IMapSettingState> {
  public defaultSettings$: Observable<{
    defaultCenter: ILocation;
    defaultZoom: number;
    resultsLimit?: number;
  } | null> = this.select((state) => {
    if (!state?.mapSetting) return null;
    return {
      defaultCenter: {
        lng: state.mapSetting.startingLongitude,
        lat: state.mapSetting.startingLatitude
      },
      defaultZoom: state.mapSetting.startingZoom,
      resultsLimit: state.mapSetting.resultsLimit
    };
  });
  public viewType$: Observable<'map' | 'list'> = this.select((s) => s.viewType);
  public constructor(protected store: MapSettingStore) {
    super(store);
  }
}
